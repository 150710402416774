import React, { useState, useRef } from "react";
import { graphql } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";
import Section from "../components/Section";
import SEO from "../components/SEO";
import styled from "styled-components";
import { below } from "../styles/Breakpoints";

import { StyledProjectContainer } from "../styles/Containers";

export default function Abolition({ data, location }) {
  const block = data.abolition.nodes[0];

  // console.log(block);

  const StyledBlockContent = styled.div`
    img {
      max-width: calc(50% - 4rem);
      float: left;
      margin-inline: 2rem;
      margin-bottom: 2rem;

      ${below.mobileL`
        max-width: 100%;
        margin-inline: 0;
      `}
    }
  `;

  return (
    <>
      <SEO
        title={block?.title}
        location={location}
        description={block?._rawBody[0].children[0].text}
      />
      <Section fullScreen width="900px">
        <StyledProjectContainer width="870px">
          <h1>{block?.title}</h1>
          <div className="header-underline"></div>
          <StyledBlockContent>
            <BlockContent
              blocks={block?._rawBody}
              projectId="hq5iqyv5"
              dataset="production"
            />
          </StyledBlockContent>
        </StyledProjectContainer>
      </Section>
    </>
  );
}

export const abolitionQuery = graphql`
  query AbolitionQuery {
    abolition: allSanityAbolition {
      nodes {
        title
        _rawBody
      }
    }
  }
`;
