import styled, { css } from "styled-components";
import { above, below } from "./Breakpoints";

export const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 900px;
  & img {
    width: 200px;
    height: 200px;
    margin-bottom: 1rem;
    border: 1px solid var(--black);
  }
  & > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 0.33;
    max-width: 200px;
  }

  ${above.tablet`
    flex-direction: row;
  `}

  & p {
    ${below.tablet`
  margin-bottom: 2rem;
  `}
  }
`;

export const StyledProjectContainer = styled.div`
  max-width: ${({ width }) => (width ? width : "600px")};
  width: 100%;

  & p {
    max-width: ${({ width }) => (width ? width : "600px")};
  }

  & h1 {
    margin-bottom: 1.2rem;
    width: initial;
    text-align: center;
  }

  a[href$="militaryviolence@reclaimjusticemovement.org"] {
    ${below.mobileM`
      font-size: .78rem;
      display:inline-block;
      margin-top: .25rem;
    `}
  }
`;
